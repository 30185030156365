// import Form from 'react-bootstrap/Form';

import Banner from "./components/Banner";
import "./components/Banner.css";
import Ragister from "./components/Ragister";
import "./components/Ragister.css";

function App() {
  return (
    <div className="App">
      <>
      <Banner/>
      <Ragister/>
      </>
    </div>
  );
}

export default App;
