import React from 'react'

function Banner() {
  return (
    <div>
      <>
      <div className="left">
        <h5 className='alreadyAcc'>Already have an account?</h5>
        <button className='btn1'>Sign up</button>
        <h3 className='conclution'>Discover new things on Superapp</h3>
        <img src='https://s3-alpha-sig.figma.com/img/3bdb/ec6f/74b004fdcf87b04992ba3c2faf4d5bf9?Expires=1678060800&Signature=pHIvzM6pZzT8DAwxAJEFCtrAFbJWbJEJedNrrlgX78k1zm4BRsudBuDTFwjuU9LOWG-FrZDLfQbnQJ-FlS2-T85uYo72ckvWaPXUvqyC5C-C1~u4U9lIAGF2QffJbNFT9wj0KlzMeorVX~jHhBmQ-Uy53-JIk2YFix-QtPDPKqJkcC3DT3m~mCv3L6UYG1~9bVd4GB25UVvPB8iZjFKRF1m6cFIsNC270h~fzk7xnuGHhcnvGYSLEVRYWgaLFcWoAIqPz8YUEgbSudZFRrvTmnvS1Va8EMzGemDnJ7YtuHCdvo7OINK61oeSUMD4-w~vyb4ryAnrKsoKNyfxm81ayg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' width='639rem' height='767rem'/>
      </div>
      </>
    </div>
  )
}

export default Banner
