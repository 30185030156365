
import  {useState,useEffect} from 'react'

const Ragister =()=>{

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> this is useState part
  const [active, setActive] = useState(false)

  const [FullName1,setFullName1]= useState({
    fuser: "",
  });
  const [FullName2,setFullName2]= useState({
    fuser: "",
  });
  const [FullEmail,setFullEmail]= useState({
    fuser: "",
  });
  const [FullNumber,setFullNumber]= useState({
    fuser: "",
  });
  const [FullCheck,setFullCheck]= useState({
    fuser: "",
  });
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> this was useState part


// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>this is  Button event part
  const handleOnName=(event)=>{
    // console.log(event.target.value);
    setFullName1(event.target.value);
  };
  const handleOnUname=(event)=>{
    // console.log(event.target.value);
    setFullName2(event.target.value);
  };
  const handleOnEmail=(event)=>{
    // console.log(event.target.value);
    setFullEmail(event.target.value);
  };
  const handleOnNumber=(event)=>{
    // console.log(event.target.value);
    setFullNumber(event.target.value);
  };
  const handleONCheck=(event)=>{
    // console.log(event.target.value);
    setFullCheck(event.target.value);
  };
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> this was of Button event part


// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>this is  isFromSubmit part
  const isFromSubmit =()=>{
    for (const value in FullName1) {
        if(FullName1[value].length===0){
            return false
        }
    }
    for (const value in FullName2) {
      if(FullName2[value].length===0){
          return false
      }
  }
    for (const value in FullEmail) {
      if(FullEmail[value].length===0){
          return false
      }
  }
  for (const value in FullNumber) {
    if(FullNumber[value].length===0){
        return false
    }
}
for (const value in FullCheck) {
  if(FullCheck[value].length===0){
      return false
  }
}
    return true
  }
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> isFromSubmit part


//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> useEffect part
    useEffect(()=>{
      if(FullName1.length>0 && isFromSubmit()){
          setActive(true)
        } 
        else if(FullName2.length>0 && isFromSubmit()){
          setActive(true)
        } 
        else if(FullEmail.length>0 && isFromSubmit()){
          setActive(true)
      }
      else if(FullNumber.length>0 && isFromSubmit()){
        setActive(true)
      }
      else if(FullCheck.length>0 && isFromSubmit()){
        setActive(true)
      }
      },)
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>useEffect part

  return (
    <div>
      <>
      <div className="right">
        <h2 className='title'>Super app</h2>
        <p className='newAcc'>Create your new account</p>
        <p className='email'>Email |</p>
        <p className='google'>Google</p>
        <div className="input">
      <input type='text' placeholder='Enter Your Name'name='fname' value={FullName1.fname} onChange={handleOnName} size={30}/><br/><br/>
      <input type='text' placeholder='Enter Your  UserName'name='Uname'value={FullName2.Uname} onChange={handleOnUname} size={30}/><br/><br/>
      <input type='email' placeholder='Enter Your Email'size={30} name='email' value={FullEmail.email} onChange={handleOnEmail} /><br/><br/>
      <input type='text' placeholder='91+ Enter Your Number' maxLength='10' name='number' value={FullNumber.number} onChange={handleOnNumber} size={30}/><br/>
      <input type='checkbox' className='checkbox' name='check' value={FullCheck.check} onChange={handleONCheck}/>
      <p className='checkboxText'>Share my registration data with Superapp</p><br/>
      {active?<button className='btn2-active'>Sign up</button>:<button className='btn2'>Sign up</button>}
      <p className='text1'>By clicking on Sign up. you agree to Superapp Terms and Conditions of Use</p>
      <p className='text2'>To learn more about how Superapp collects, uses, shares and protects your personal data please head Superapp Privacy Policy</p>
        </div>
      </div>
      </>
    </div>
  )
}

export default Ragister

